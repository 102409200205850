@import './fonts.css';
@import 'variables';
@import 'extensions';

////// BOOTSTRAP 4 IMPORTS AND OVERRIDES //////

// Overrides

@import 'node_modules/bootstrap/scss/bootstrap';

////// END BOOTSTRAP 4 IMPORTS AND OVERRIDES //////

:root {
    --page-header-bg-color: #{$page-header-bg-color};
    --nav-bg-color: #{$nav-bg-color};
    --nav-left-bg-color: #{$nav-left-bg-color};
    --nav-left-hover-bg-color: #{$nav-left-hover-bg-color};
    --scrollbarBG: #cfd8dc;
    --thumbBG: #90a4ae;
}

html {
    scroll-behavior: smooth;
}
body {
    margin: 0;
    font-family: system-ui, -apple-system,
        /* Firefox supports this but not yet `system-ui` */ 'Segoe UI', Roboto,
        Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

////// UTILITY CLASSES //////

//// LAYOUT

.grid {
    display: grid;
}

.z-index-2 {
    z-index: 2;
}

.z-index-4 {
    z-index: 4;
}

.z-index-6 {
    z-index: 6;
}

.top-2 {
    top: 2px;
}

.top-100px {
    top: 100px;
}

.sticky {
    position: sticky;
}

.hidden {
    display: none !important;
}

.align-items-center1 {
    align-items: center;
}

//// END LAYOUT

//// SIZING

.min-h-screen {
    min-height: 100vh;
}

.max-w-500px {
    max-width: 500px;
}

.max-w-250px {
    max-width: 250px;
}

.w-250px {
    width: 250px;
}

.max-w- {
    &12_5rem {
        max-width: 15.625rem !important;
    }

    &12rem {
        max-width: 12rem !important;
    }

    &8_75rem {
        max-width: 8.75rem !important;
    }

    &10rem {
        max-width: 10rem !important;
    }

    &18_75rem {
        width: 18.75rem;
    }
}

.w-13_75rem {
    width: 13.75rem;
}

//// END SIZING

//// SPACING

.mt-2x {
    margin-top: map-get($map: $spacers, $key: 2x);
}

.pl-6 {
    padding-left: 1.5rem !important;
}

.px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
}

.pb-8 {
    padding-bottom: 2rem !important;
}

.px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse)) !important;
}

.m {
    &b- {
        &8rem {
            margin-bottom: 8rem;
        }
    }
}

@include media-breakpoint-up(sm) {
    .sm\: {
        &mb-0 {
            margin-bottom: 0 !important;
        }

        &mr-3 {
            margin-right: 1rem !important;
        }
    }
}

@include media-breakpoint-down(xs) {
    .xs-down\: {
        &col {
            flex-direction: column;
        }

        &mb- {
            &0_4rem {
                margin-bottom: 0.4rem;
            }

            &1rem {
                margin-bottom: 1rem;
            }
        }

        &-m {
            &l- {
                &0_25rem {
                    margin-left: -0.25rem !important;
                }

                &4rem {
                    margin-left: -4rem;
                }
            }

            &r- {
                &1rem {
                    margin-right: -1rem;
                }

                &0_35rem {
                    margin-right: -0.35rem !important;
                }
            }
        }

        &p {
            &t- {
                &0_25rem {
                    padding-top: 0.25rem;
                }
            }

            &x- {
                &0_25rem {
                    padding-left: 0.25rem !important;
                }
            }

            &x- {
                &0_25rem {
                    padding-right: 0.25rem !important;
                }
            }
        }

        &w- {
            &0 {
                width: 0;

                &\! {
                    width: 0 !important;
                }
            }

            &7_5-rem {
                width: 7.5rem;
            }

            &18_75rem {
                width: 18.75rem;
            }

            &60pct {
                width: 60%;
            }
        }

        &max- {
            &w- {
                &12rem {
                    max-width: 12rem;
                }
            }
        }

        &min- {
            &w- {
                &100 {
                    min-width: 100%;
                }
            }
        }

        &text- {
            &center {
                text-align: center;
            }
        }

        &-top- {
            &2rem {
                &\! {
                    top: -2rem !important;
                }
            }
        }

        &flex {
            display: flex;
        }

        &align-items-initial {
            align-items: initial;
        }
    }
}

@include media-breakpoint-up(xs) {
    .xs-up\: {
        pl-1_5rem {
            padding-left: 1.5rem;
        }

        ml-1_5rem {
            margin-left: 1.5rem;
        }
    }
}

@include media-breakpoint-down(sm) {
    .sm-down\: {
        &order-1 {
            order: 1;
        }

        &col {
            flex-direction: column;
        }

        &m {
            &b- {
                &1rem {
                    margin-bottom: 1rem;
                }
            }
        }

        &w- {
            &100 {
                width: 100%;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .lg-down\: {
        &m {
            &b- {
                &1rem {
                    margin-bottom: 1rem;
                }
            }
        }
    }
}

//// END SPACING

//// TYPOGRAPHY

.italic {
    font-style: italic !important;
}

.text-sm {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
}

.text-lg {
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
}

.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.text-nav-bg {
    --tw-text-opacity: 1;
    // color: rgba(82, 114, 132, var(--tw-text-opacity));
    color: var(--nav-bg-color) !important;
}

.text-justify {
    text-align: justify;
}

.font-semibold {
    font-weight: 600 !important;
}

.font-bold {
    font-weight: 700 !important;
}

.ml-2 {
    margin-left: 0.5rem !important;
}

//// END TYPOGRAPHY

//// BACKGROUND

.bg-nav-bg {
    background-color: var(--nav-bg-color) !important;
}

.bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
}

//// END BACKGROUND

.shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

//// INTERACTIVITY

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.cursor-pointer {
    cursor: pointer;
}

.pointer-events-none {
    pointer-events: none;
}

//// END INTERACTIVITY

//// EFFECTS

.opacity- {
    &40 {
        opacity: 0.4;
    }

    &60 {
        opacity: 0.6;
    }
}

//// END EFFECTS

//// Other handy utilities

//// CUSTOM SCROLLBAR

.custom-scroll-bar {
    @extend %scroll-bar;
}

//// END CUSTOM SCROLLBAR

.visually-hidden {
    width: 1px;
    height: 1px;
    visibility: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    display: block;
}


//// END Other handy utilities

////// END UTILITY CLASSES //////
