%scroll-bar {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        /* background: var(--scrollbarBG); */
        background: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--thumbBG);
        border-radius: 6px;
        border: 2px solid var(--scrollbarBG);
    }
}

%admin-application-header-2 {
    padding-left: 20px;
    background-color: #aec7dd;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 18px;
    margin-bottom: 0;
}
