@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Body
$body-bg: #f5f8fa;

// Brands
$brand-primary: #3097d1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
// $icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Avenir", Helvetica, Arial, sans-serif;
$font-size-base: 0.875rem;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

// Nav
$page-header-bg-color: #21495f;
$nav-bg-color: #034062;
// $nav-left-bg-color: #4398c9;
$nav-left-bg-color: #3097D1;
$nav-left-hover-bg-color: #034062;

$signature-image-locked-bg-color: lighten($nav-bg-color, 40%);

//// SIZING UTILITIES

$spacers: map-merge(
    (
        2x: (
            $spacer * 2,
        ),
    ),
    $spacers
);
